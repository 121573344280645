import { useStaticQuery, graphql } from 'gatsby';

const useCountriesData = () => {
    const countriesData = useStaticQuery(graphql`
        query dealerCountries {
            wordpress {
                dealers(where: {status: PUBLISH}, first: 10000) {
                    nodes {
                        ACFDealerBlockFields {
                            country
                        }
                    }
                }
            }
        }
    `);
    
    const countries = countriesData.wordpress.dealers.nodes;
    
    // sort by country name, a to z
    countries.sort((a, b) => {
        const countryA = a.ACFDealerBlockFields.country;
        const countryB = b.ACFDealerBlockFields.country;
        return ((countryA < countryB) ? -1 : ((countryA > countryB) ? 1 : 0));
    });
    
    return countries;
};

export default useCountriesData;
