import React from 'react';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";

import Button from '../components/component.button';
import Placeholder from '../assets/images/row-the-future-1.png';

const Info = ({ parallax, language }) => {
    const data = useStaticQuery(graphql`


        fragment infoContent on WORDPRESS_GlobalContent {
            ACFWhyWintechRacingBlockFields {
                title
                label
                images {
                    image {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 680) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                link {
                    url
                }
                reasons {
                    title
                    content
                }
            }
        }


        query Info {
            wordpress {
                EN: globalContent(idType: SLUG, id: "why-wintech-racing-en") {
                    ...infoContent
                }
                FR: globalContent(idType: SLUG, id: "why-wintech-racing-fr") {
                    ...infoContent
                }
                ES: globalContent(idType: SLUG, id: "why-wintech-racing-es") {
                    ...infoContent
                }
                ZH: globalContent(idType: SLUG, id: "why-wintech-racing-zh") {
                    ...infoContent
                }
                DE: globalContent(idType: SLUG, id: "why-wintech-racing-de") {
                    ...infoContent
                }
                PL: globalContent(idType: SLUG, id: "why-wintech-racing-pl") {
                    ...infoContent
                }
            }
        }
    `);

    let info;
    // language is taken from the page's language code
    if (typeof data.wordpress[language] !== "undefined") {
        info = data.wordpress[language].ACFWhyWintechRacingBlockFields;
    } else {
        info = data.wordpress['EN'].ACFWhyWintechRacingBlockFields;
    }

    const offsets = [
        "-0.2",
        "-0.14",
        "0",
        "0.42",
        "0.10"
    ];

    const speeds = [
        "-0.05",
        "-0.025",
        "0",
        "0.125",
        "0.05"
    ];

    return (
        <div class="c-info__outer">
            <div className="container">
                <div className={"c-info"}>
                    <div className="row">

                        <div className="col-md-6 col-lg-6 d-none d-md-flex align-items-center justify-content-lg-center">
                            <div className="c-info__images">
                                <Img fluid={info.images[0].image.imageFile.childImageSharp.fluid}></Img>
                            </div>
                        </div>

                        <div className="col-md-6 c-info__col">
                            <div className="c-info__details">

                                <ul className="c-info__details-list">
                                    {info.reasons && info.reasons.map((reason, index) => (
                                        <li key={index} className="c-info__details-item">
                                            <h3 className="c-info__subtitle">{reason.title}</h3>
                                            <div dangerouslySetInnerHTML={{ __html: reason.content }}></div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="c-info__button">
                                    <Button link to={info.link && info.link.url} text={info.label} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
