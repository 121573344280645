import React from 'react';
import Img from 'gatsby-image';

/**
 * Wrapper for Gatsby image component 
 * Used where the image maybe smaller than the width in the fluid query
 * 
 * More info - https://www.gatsbyjs.org/packages/gatsby-image/#avoiding-stretched-images-using-the-fluid-type
 * 
 * @param {*} props
 * @returns
 */
const NonStretchedImage = props => {
    let normalisedProps = props;
    
    if (props.fluid && props.fluid.presentationWidth) {
        normalisedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: props.fluid.presentationWidth,
                margin: "0 auto", // Used to center the image
            },
        };
    }
  
    return <Img {...normalisedProps} />;
}

export default NonStretchedImage;
